import React, { Component } from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class YearPicker extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            years: [],
            selectedYear: new Date().getFullYear()
        }
        
        this.loadYears = this.loadYears.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidMount() {
        this.loadYears();
    }

    handleChange(e) {
        this.setState({
            selectedYear: e.target.value
        });

        if(this.props.onChange)
            this.props.onChange(e.target.value);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.loadYears()
        }
    }

    loadYears() {
        const api = new Api();
        api.TimeEntry.getYears()
            .then((data) => {
                this.setState({
                    years: data
                });
            })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Jahr-Auswahl konnte nicht geladen werden."
                }));
            });
    }
    
    
    render () {
        console.log(this.state);
        return (
            <div className={"YearPicker " + this.props.className}>
                <FormControl>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.selectedYear}
                        onChange={this.handleChange}
                        label="Jahr"
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {
                            this.state.years.map((year) => {
                                return (
                                    <MenuItem key={year} value={year}>{year}</MenuItem>
                                );
                            })
                        }                        
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default connect()(YearPicker);