import Http from "./http";

export default class TimeEntry {
    constructor(route = "/timeEntry") {
        this.route = route;
    }

    getById(id) {
        return Http.get(`${this.route}/${id}`);
    }

    getByProjectId(id) {
        return Http.get(`${this.route}/project/${id}`);
    }

    getByTeamMemberId(id) {
        return Http.get(`${this.route}/teammember/${id}`);
    }
    
    filter(projectId, teamMemberId, date, dateRelation, type = "all") {
        return Http.get(`${this.route}/filter?projectId=${projectId ? projectId : ""}&teamMemberId=${teamMemberId ? teamMemberId : ""}&date=${date ? date : ""}&dateRelation=${dateRelation ? dateRelation : ""}&type=${type}`);
    }

    getAll() {
        return Http.get(`${this.route}/all`);
    }

    create(timeEntry) {
        return Http.post(`${this.route}`, timeEntry);
    }

    createMulti(timeEntries) {
        return Http.post(`${this.route}/multi`, timeEntries);
    }

    delete(timeEntryId) {
        return Http.delete(`${this.route}/${timeEntryId}`);
    }

    update(timeEntry) {
        return Http.put(`${this.route}/${timeEntry.timeEntryId}`, timeEntry);
    }

    getProjectSums(year = new Date().getFullYear(), teamMemberId="", projectId="", month="", withArchived = false, type = "all") {
        return Http.get(`${this.route}/sum/${year}/projects?projectId=${projectId}&teamMemberId=${teamMemberId}&monthNumber=${month}&withArchived=${withArchived}&type=${type}`);
    }

    getTeamMemberSums(year = new Date().getFullYear(), teamMemberId="", projectId="", month="", withArchived = false, type = "all") {
        return Http.get(`${this.route}/sum/${year}/teammembers?projectId=${projectId}&teamMemberId=${teamMemberId}&monthNumber=${month}&withArchived=${withArchived}&type=${type}`);
    }

    getMonthSums(year = new Date().getFullYear(), teamMemberId="", projectId="", month="", withArchived = false, type = "all") {
        return Http.get(`${this.route}/sum/${year}/months?projectId=${projectId}&teamMemberId=${teamMemberId}&monthNumber=${month}&withArchived=${withArchived}&type=${type}`);
    }

    getStatistic(year = new Date().getFullYear(), teamMemberId="", projectId="", month="", withArchived = false, type = "all") {
        return Http.get(`${this.route}/statistic/${year}?projectId=${projectId}&teamMemberId=${teamMemberId}&monthNumber=${month}&withArchived=${withArchived}&type=${type}`);
    }


    getYears() {
        return Http.get(`${this.route}/years`);
    }

}