import React, { Component } from 'react';
import { Hidden, Paper} from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar';
const monthNames = [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ];
const prefersDarkMode = window.localStorage.getItem("use-darkMode");

export class StatisticGraph extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            dataProjects: [],
            dataMembers: [],
            dataMonths: [],
            keysProjects: [],
            keysMembers: [],
            keysMonths: []
        }
        
        this.structureData = this.structureData.bind(this);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.setState(
                {
                    dataProjects: this.structureData(this.props.statistic),
                    dataMembers: this.structureData(this.props.statistic, "members"),
                    dataMonths: this.structureData(this.props.statistic, "months"),
                    keysProjects: this.props.statistic.projects.map((value) => value.projectName),
                    keysMembers: this.props.statistic.members.map((value) => value.teamMemberName),
                    keysMonths: this.props.statistic.months.map((value) => monthNames[value.monthNumber -1])
                }
            )
        }
    }

    componentDidMount() {
        this.setState(
            {
                dataProjects: this.structureData(this.props.statistic),
                dataMembers: this.structureData(this.props.statistic, "members"),
                dataMonths: this.structureData(this.props.statistic, "months"),
                keysProjects: this.props.statistic.projects.map((value) => value.projectName),
                keysMembers: this.props.statistic.members.map((value) => value.teamMemberName),
                keysMonths: this.props.statistic.months.map((value) => monthNames[value.monthNumber -1])                
            }
        )
    }

    structureData(statistic, type="projects") {
        let structuredData = [];
        
        let projects = {
            name: "Projekte"
        }

        let members = {
            name: "Teammitglieder"
        }
        
        let months = {
            name: "Monate"
        }
        
        if(type === "projects") {
            statistic.projects.map((value) => {
                Object.assign(projects, {
                    [value.projectName]: value.sum / 60
                })
            });
            structuredData.push(projects);
        }

        if(type === "members") {
            statistic.members.map((value) => {
                Object.assign(members, {
                    [value.teamMemberName]: value.sum / 60
                })
            });
            structuredData.push(members);
        }

        if(type === "months") {
            statistic.months.map((value) => {
                Object.assign(months, {
                    [monthNames[value.monthNumber - 1]]: value.sum / 60
                })
            });
            structuredData.push(months);
        }
        
        return structuredData;
    }
    
    render () {
        const theme ={
            axis: {
                fontSize: "14px",
                    tickColor: "#eee",
                    ticks: {
                    line: {
                        stroke: "#555555"
                    },
                    text: {
                        fill:"#FFFFFF"
                    }
                },
                legend: {
                    text: {
                        fill: "#fff"
                    }
                }
            },
            legends: {
                text: {
                    fill: "#fff"
                }
            }
        };
        
        
        
        return (
            <div className="ProjectStatistic" >
                <Paper elevation={3}>
                    <Hidden only="xs">
                        <div style={{ height: 400, display: "flex" }}>
                        <ResponsiveBar                            
                            data={this.state.dataProjects}
                            keys={this.state.keysProjects}
                            theme={ prefersDarkMode ? theme : "" }
                            indexBy="name"
                            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={{ scheme: 'paired' }}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: 'Erfasste Zeit (h)',
                                legendPosition: 'middle',
                                legendOffset: -40,
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'custom', modifiers: [ [ 'darker', 1.6 ] ] }}
                            legends={[
                                {
                                    dataFrom: 'keys',
                                    anchor: 'bottom-right',
                                    direction: 'column',
                                    justify: false,
                                    translateX: 120,
                                    translateY: 0,
                                    itemsSpacing: 1,
                                    itemWidth: 100,
                                    itemHeight: 15,
                                    itemDirection: 'left-to-right',
                                    itemTextColor: theme.axis.legend.text.fill,
                                    itemOpacity: 0.85,
                                    symbolSize: 10,
                                    symbolShape: "circle",
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemOpacity: 1
                                            }
                                        }
                                    ]
                                }
                            ]}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            tooltip={({ id, value, color }) => (
                                <div
                                style={{
                                padding: 0,                                
                                background: '#fff', 
                                color: "#000",    
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                                >
                                    <div style={
                                        {
                                            background: color,
                                            height: "15px",
                                            width: "15px",
                                            marginRight: "5px"
                                        }
                                    }/>
                                    <span>
                                        { id + ": " + value + " Stunden" }
                                    </span>
                                </div>
                                )}
                        />
                            <ResponsiveBar
                                data={this.state.dataMembers}
                                keys={this.state.keysMembers}
                                indexBy="name"
                                theme={ prefersDarkMode ? theme : "" }
                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'paired' }}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 1,
                                        itemWidth: 100,
                                        itemHeight: 15,
                                        itemDirection: 'left-to-right',
                                        itemTextColor: theme.axis.legend.text.fill,
                                        itemOpacity: 0.85,
                                        symbolSize: 10,
                                        symbolShape: "circle",
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={({ id, value, color }) => (
                                    <div
                                        style={{
                                            padding: 0,
                                            background: '#fff',
                                            color: "#000",
                                            display: 'flex',
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={
                                            {
                                                background: color,
                                                height: "15px",
                                                width: "15px",
                                                marginRight: "5px"
                                            }
                                        }/>
                                        <span>
                                        { id + ": " + value + " Stunden" }
                                    </span>
                                    </div>
                                )}
                            />
                            <ResponsiveBar
                                data={this.state.dataMonths}
                                keys={this.state.keysMonths}
                                indexBy="name"
                                theme={ prefersDarkMode ? theme : "" }
                                margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'paired' }}
                                borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: '',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 1,
                                        itemWidth: 100,
                                        itemHeight: 15,
                                        itemDirection: 'left-to-right',
                                        itemTextColor: theme.axis.legend.text.fill,
                                        itemOpacity: 0.85,
                                        symbolSize: 10,
                                        symbolShape: "circle",
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={({ id, value, color }) => (
                                    <div
                                        style={{
                                            padding: 0,
                                            background: '#fff',
                                            color: "#000",
                                            display: 'flex',
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <div style={
                                            {
                                                background: color,
                                                height: "15px",
                                                width: "15px",
                                                marginRight: "5px"
                                            }
                                        }/>
                                        <span>
                                        { id + ": " + value + " Stunden" }
                                    </span>
                                    </div>
                                )}
                            />
                        </div>
                    </Hidden>
                </Paper>
            </div>
        );
    }
}
