import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import {Container} from "@material-ui/core";
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
const prefersDarkMode = window.localStorage.getItem("use-darkMode");

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    const theme = 
            createTheme({
              palette: {
                type: prefersDarkMode ? 'dark' : 'light',
              },
            });
    
    return (
      <div className={this.props.className + (prefersDarkMode ? " dark" : "")}>
        <NavMenu />
          <ThemeProvider theme={theme}>
              <CssBaseline />
              <Container>
                {this.props.children}
              </Container>
          </ThemeProvider>
      </div>
    );
  }
}
