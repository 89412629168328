import { SHOW_SNACK, HIDE_SNACK } from "../actions/snackActions";

const initialState = {
    message: "",
    type: "",
    isVisible: false
}

export default class SnackReducer {

    static reducer(state = initialState, action) {
        if(action.type === SHOW_SNACK) {
            return Object.assign({}, state, {
                message: action.data.message,
                type: action.data.type,
                isVisible: true
            });
        }

        if(action.type === HIDE_SNACK) {
            return Object.assign({}, state, {
                message: "",
                type: "",
                isVisible: false
            });
        }

        return state;
    }

}
