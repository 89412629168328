import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class TeamMemberEditButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            teamMember: {
                teamMemberId: 0,
                name: "",
                email: "",
                password: ""
            }
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }
    
    componentDidMount() {       
        this.setState({
            teamMember: this.props.teamMember
        });
    }

    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        const createForm = document.getElementById("updateTeamMemberForm");

        if(createForm.checkValidity()) {
            let api = new Api();
            api.TeamMember.update(this.state.teamMember.teamMemberId, {
                user: {
                    name: this.state.teamMember.name,
                    email: this.state.teamMember.email,
                    passwordHashed: "",
                    teamMemberId: this.state.teamMember.teamMemberId
                },
                passwordUnhashed: this.state.teamMember.password
            })
                .then((data) => {
                        this.props.saveAction();
                        this.handleClose();
                    this.props.dispatch(SnackActions.showSnack({
                        type: "success",
                        message: "Teammitglied wurde erfolgreich gespeichert."
                    }));
                })
                .catch((data) => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Teammitglied konnte nicht gespeichert werden."
                    }));
                })
        }
    }

    handleNameChange(e) {
        let teamMember = this.state.teamMember;

        this.setState(
            {
                teamMember: {
                    teamMemberId: teamMember.teamMemberId,
                    name: e.target.value,
                    password: teamMember.password,
                    email: teamMember.email,
                }
            }
        );
    }

    handleEmailChange(e) {
        let teamMember = this.state.teamMember;

        this.setState(
            {
                teamMember: {
                    teamMemberId: teamMember.teamMemberId,
                    name: teamMember.name,
                    password: teamMember.password,                    
                    email: e.target.value,
                }
            }
        );
    }

    handlePasswordChange(e) {
        let teamMember = this.state.teamMember;

        this.setState(
            {
                teamMember: {
                    teamMemberId: teamMember.teamMemberId,
                    name: teamMember.name,
                    password: e.target.value,
                    email: teamMember.email,
                }
            }
        );
    }

    render () {
        return (
            <div>
                {
                    this.props.alternateBtn ?
                        <IconButton variant="outlined" color="primary" onClick={this.handleClickOpen}>
                            <EditIcon />
                        </IconButton>
                        :
                        <Button variant="outlined" color="primary" onClick={this.handleClickOpen} >
                            { this.props.buttonText }
                        </Button>
                }
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"sm"} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Teammitglied <b>{ this.state.teamMember.name }</b> bearbeiten
                    </DialogTitle>
                    <DialogContent dividers>
                        <form id="updateTeamMemberForm">
                            <TextField placeholder="Vor- und Nachname eingeben..."
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       margin="normal"
                                       fullWidth
                                       value={this.state.teamMember.name}
                                       required
                                       label="Vor- und Nachname"
                                       variant="outlined"
                                       name="name"
                                       onChange={this.handleNameChange}/>
                            <TextField placeholder="E-Mailadresse eingeben..."
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       margin="normal"
                                       fullWidth
                                       value={this.state.teamMember.email}
                                       required
                                       label="E-Mailadresse"
                                       variant="outlined"
                                       name="email"
                                       type="email"
                                       onChange={this.handleEmailChange}/>
                            <TextField placeholder="Passwort eingeben..."
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       margin="normal"
                                       fullWidth
                                       value={this.state.teamMember.password}
                                       required
                                       label="Passwort"
                                       variant="outlined"
                                       name="password"
                                       type="password"
                                       onChange={this.handlePasswordChange}/>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleSubmit}>
                            Teammitglied speichern
                        </Button>
                        <Button  color="secondary" onClick={this.handleClose}>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect()(TeamMemberEditButton);