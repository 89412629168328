import React  from "react";
import TokenStorage from "../utils/tokenStorage";

export const PrivateComponent = ({component: Component, role: Role, showMessage: ShowMessage, message: Message}) =>
    (
        <>
            {
                TokenStorage.isAuthenticated() ?
                    Role ?
                        Role === TokenStorage.getRole() ?
                            Component :
                            null :
                    Component
                :
                (ShowMessage ? <div>{Message}</div> : null)
            }
        </>
    )

