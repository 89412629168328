import {CHANGE_USER, CLEAR_USER, LOGOUT} from "../actions/authenticationActions";
import TokenStorage from "../utils/tokenStorage";

const initialState = {
    isAuthUser: false,
    email: "",
    role: "",
    userId: 0
};

export default class AuthenticationReducer {
    static reducer(state = initialState, action) {
        if(action.type === CHANGE_USER) {
            return Object.assign({}, state, {
                email: action.data.email,
                role: action.data.role,
                isAuthUser: action.data.email !== "" && action.data.role !== "",
                userId: action.data.id
            });
        }

        if(action.type === CLEAR_USER) {
            state = null;
            localStorage.clear();
        }

        if(action.type === LOGOUT) {
            state = null;
            TokenStorage.clear();
            localStorage.clear();
        }

        return state;
    }
}
