import Project from "./project";
import TeamMember from "./teamMember";
import TimeEntry from "./timeEntry";
import Authentication from "./authentication";

export default class Api {
    constructor() {
        this.Project = new Project();
        this.TeamMember = new TeamMember();
        this.TimeEntry = new TimeEntry();
        this.Auth = new Authentication();
    }
}