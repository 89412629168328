import React, { Component } from 'react';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import {Button, Grid, IconButton, InputAdornment, TextField} from "@material-ui/core";
import ProjectSelect from "./ProjectSelect";
import TeamMemberSelect from "./TeamMemberSelect";
import './timeEntryMultiForm.scss';
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";
let moment = require('moment');
const dateNow = moment().format('yyyy-MM-DD');

class TimeEntryMultiForm extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projectId: window.localStorage.getItem("lastSelectedProject"),
            teamMemberId: window.localStorage.getItem("lastSelectedMember"),
            timeEntries: [
                {
                    date: dateNow,
                    time: 0,
                    description: ""
                }
            ]
        }
        this.handleAddTime = this.handleAddTime.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleProjectSelect = this.handleProjectSelect.bind(this);
        this.handleTeamMemberSelect = this.handleTeamMemberSelect.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleRemoveTime = this.handleRemoveTime.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDescriptionChange(e, index) {
        let timeEntries = this.state.timeEntries;
        timeEntries[index].description = e.target.value
        
        this.setState({
            timeEntries: timeEntries
        });
    }

    handleProjectSelect(projectId) {
        this.setState({
            projectId: projectId
        });
    }

    handleTeamMemberSelect(memberId) {
        this.setState({
            teamMemberId: memberId
        });
    }

    handleDateChange(e, index) {
        let timeEntries = this.state.timeEntries;
        timeEntries[index].date = e.target.value
        
        this.setState(
            {
                timeEntries: timeEntries
            }
        );
    }

    handleTimeChange(e, index) {
        let timeEntries = this.state.timeEntries;
        let inputValue = e.target.value;
        if(inputValue.includes(',')) {
            inputValue = inputValue.replace(',', '.');
        }

        timeEntries[index].time = parseFloat(inputValue) * 60;

        if(Number.isNaN(timeEntries[index].time)) {
            this.setState( {
                timeEntries: timeEntries
            });

            return;
        }
        
        if(timeEntries[index].time < 0) {
            timeEntries[index].time = 0
            this.props.dispatch(SnackActions.showSnack({
                type: "warning",
                message: "Zeiten unter 0 Stunden (negativ) können nicht erfasst werden."
            }));
        }

        if(timeEntries[index].time >= (24 * 60)) {
            timeEntries[index].time = 24 * 60
            this.props.dispatch(SnackActions.showSnack({
                type: "warning",
                message: "Für ein Datum kann die erfasste Zeit nicht größer als 24 Stunden sein."
            }));
        }
        
        if(timeEntries[index].time%1 !== 0) {
            if(Math.round(timeEntries[index].time) > 24 * 60) {
                timeEntries[index].time = 24 * 60;
            }else if (Math.round(timeEntries[index].time) < 0) {
                timeEntries[index].time = 0;
            }else {
                timeEntries[index].time = Math.round(timeEntries[index].time / 60) * 60;
            }            
            this.props.dispatch(SnackActions.showSnack({
                type: "warning",
                message: "Zeiten können nur in 0,25-er Schritten eingegeben werden."
            }));
        }
        
        this.setState( {
            timeEntries: timeEntries
        });
    }


    handleAddTime(e) {
        let timeEntries = this.state.timeEntries;
        timeEntries.push({
            date: dateNow,
            time: 0,
            description: ""
        });
        this.setState({
            timeEntries: timeEntries
        })
    }

    handleRemoveTime(index) {
        let timeEntries = this.state.timeEntries;
        timeEntries.splice(index, 1);
        this.setState({
            timeEntries: timeEntries
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const validity = e.target.checkValidity();

        if(validity || this.props.onSubmit){
            window.localStorage.setItem("lastSelectedMember", this.state.teamMemberId);
            window.localStorage.setItem("lastSelectedProject", this.state.projectId);
            this.props.onSubmit(this.state);
            
            this.setState(
                {
                    projectId: "",
                    teamMemberId: "",
                    timeEntries: [
                        {
                            date: dateNow,
                            time: 0,
                            description: ""
                        }
                    ]
                }
            );
        }
    }

    render () {
        return (
            <div className="TimeEntryMultiForm">
                <form onSubmit={this.handleSubmit} id={this.props.id}>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <ProjectSelect
                                    value={this.state.projectId}
                                    onChange={this.handleProjectSelect}
                                    required={true} />
                            </Grid>
                            <Grid item xs={6}>
                                <TeamMemberSelect
                                    value={this.state.teamMemberId}
                                    onChange={this.handleTeamMemberSelect}
                                    required={true} />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {
                            this.state.timeEntries ? 
                                this.state.timeEntries.map((row, index) => {
                                    return (
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <TextField
                                                    required
                                                    id="date-input"
                                                    label="Datum"
                                                    size="small"
                                                    type="date"
                                                    defaultValue={dateNow}
                                                    onChange={(e) => this.handleDateChange(e, index)}
                                                    value={row.date}
                                                    fullWidth
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    required
                                                    id="time-input"
                                                    label="Zeitaufwand"
                                                    type="number"
                                                    min={1}
                                                    size="small"
                                                    max={24}
                                                    value={row.time / 60}
                                                    onChange={(e) => this.handleTimeChange(e, index)}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">Stunden</InputAdornment>,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField
                                                    id="description-input"
                                                    label="Anmerkungen"
                                                    size="small"
                                                    placeholder="Anmerkungen"
                                                    multiline={true}
                                                    value={row.description}
                                                    onChange={(e) => this.handleDescriptionChange(e, index)}
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton color="secondary" onClick={() => this.handleRemoveTime(index)}>
                                                    <RemoveCircleOutlineOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                                :
                                 null
                        }
                    </div>
                    <div  className="TimeEntryMultiForm__form-actions">
                        <IconButton size="large" onClick={this.handleAddTime} color="primary">
                            <AddCircleOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                    </div>
                    {
                        this.props.showButton ?
                            <div  className="TimeEntryMultiForm__actions">
                                <Button variant="outlined" type="submit" color="primary">
                                    Zeiten erfassen
                                </Button>
                            </div>
                            : null
                    }
                </form>
            </div>
        );
    }

}

export default connect()(TimeEntryMultiForm);
