import React, { Component } from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Api from "../api/api";
import TeamMemberCreateButton from "./TeamMemberCreateButton";
import TeamMemberDeleteButton from "./TeamMemberDeleteButton";
import TeamMemberEditButton from "./TeamMemberEditButton";
import './teamMemberSettings.scss';
import {PrivateComponent} from "../utils/privateComponent";

export class TeamMemberSettings extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            teamMembers: [],
            loading: true
        };
        
        this.loadTeamMembers = this.loadTeamMembers.bind(this);
    }
    
    componentDidMount() {
        this.loadTeamMembers();
    }

    loadTeamMembers() {
        this.setState({
            loading: true
        });
        
        const api = new Api(window.location.origin);
        api.TeamMember.getAll()
            .then((data) => {
                this.setState(
                    {
                        teamMembers: data,
                        loading: false
                    }
                );
            })
            .catch((data) => {
                this.setState({
                    loading: false
                });
            })
    }

    render () {
        return (
            <div className="TeamMemberSettings">
                <h3>
                   Teammitglieder 
                </h3>
                <div>
                    <div  className="TeamMemberSettings__actions">
                        <PrivateComponent component={<TeamMemberCreateButton saveAction={this.loadTeamMembers} />} role={"Admin"} showMessage={false}/>
                    </div>
                    <div>
                        <TableContainer style={{ minHeight: "400px", height: "auto", maxHeight: "600px" }} component={Paper}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead className="TeamMemberSettings__table-header">
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Aktionen</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.state.loading ?
                                        <div>
                                            Lädt...
                                        </div>
                                        :
                                        this.state.teamMembers.length > 0 ?                                            
                                            this.state.teamMembers.map((row) => (
                                                <TableRow key={row.teamMemberId}>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="right" className="TeamMemberSettings__table-action">
                                                        <PrivateComponent component={<TeamMemberDeleteButton teamMember={row} deleteAction={this.loadTeamMembers} />} role={"Admin"} showMessage={false} />                                                        
                                                        <TeamMemberEditButton teamMember={row} alternateBtn={true} saveAction={this.loadTeamMembers} />                                                        
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <div>
                                                Keine Teammitglieder gefunden
                                            </div>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        );
    }
}
