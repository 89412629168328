import React, { Component } from 'react';
import {Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import ProjectSelect from "./ProjectSelect";
import TeamMemberSelect from "./TeamMemberSelect";
import './timeTrackingForm.scss';
import SnackActions from "../actions/snackActions";
import {connect} from "react-redux";
let moment = require('moment');
const dateNow = moment().format('yyyy-MM-DD');

class TimeTrackingForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            timeEntryId: 0,
            projectId: window.localStorage.getItem("lastSelectedProject"),
            teamMemberId: window.localStorage.getItem("lastSelectedMember"),
            date: dateNow,
            time: 0,
            description: ""
        }
        
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleProjectSelect = this.handleProjectSelect.bind(this);
        this.handleTeamMemberSelect = this.handleTeamMemberSelect.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        if(this.props.timeEntry) {
            this.setState({
                timeEntryId: this.props.timeEntry.timeEntryId,
                projectId: this.props.timeEntry.projectId,
                teamMemberId: this.props.timeEntry.teamMemberId,
                date: moment(this.props.timeEntry.date).format('yyyy-MM-DD'),
                time: this.props.timeEntry.timeInMinutes,
                description: this.props.timeEntry.description
            });
        }
    }

    render () {
        return (
            <div className="TimeTrackingForm">
                <form onSubmit={this.handleSubmit} id={this.props.id}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <ProjectSelect 
                                value={this.state.projectId}
                                onChange={this.handleProjectSelect}
                                required={true} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TeamMemberSelect 
                                value={this.state.teamMemberId}
                                onChange={this.handleTeamMemberSelect}
                                required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="date-input"
                                label="Datum"
                                type="date"
                                defaultValue={dateNow}
                                onChange={this.handleDateChange}
                                value={this.state.date}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="time-input"
                                label="Zeitaufwand"
                                type="number"
                                min={1}
                                max={24}
                                value={this.state.time / 60}
                                onChange={this.handleTimeChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">Stunden</InputAdornment>,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description-input"
                                label="Anmerkungen"
                                placeholder="Anmerkungen"
                                multiline={true}
                                value={this.state.description}
                                onChange={this.handleDescriptionChange}
                                fullWidth                                
                                variant="outlined"
                            />
                        </Grid>
                        <Grid className="TimeTrackingForm__actions" item xs={12}>
                            {this.props.showButton ?
                                <Button type="submit" variant="outlined" color="primary">
                                    Zeit erfassen
                                </Button>
                                :
                                null
                            }
                            {
                                this.props.additionalActions
                            }
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }

    handleDescriptionChange(e) {
        this.setState({
            description: e.target.value
        });
    }

    handleProjectSelect(projectId) {
        this.setState({
            projectId: projectId
        });
    }

    handleTeamMemberSelect(memberId) {
        this.setState({
            teamMemberId: memberId
        });
    }
    
    handleDateChange(e) {
        this.setState(
            {
                date: e.target.value
            }
        );
    }

    handleTimeChange(e) {
        let inputValue = e.target.value;
        if(inputValue.includes(',')) {
            inputValue = inputValue.replace(',', '.');
        }
        
        let timeInMinutes = parseFloat(inputValue) * 60;

        if(Number.isNaN(timeInMinutes)) {
            this.setState( {
                time: timeInMinutes
            });
            
            return;
        }
        
        if(timeInMinutes < 0) {
            timeInMinutes = 0;
            this.props.dispatch(SnackActions.showSnack({
                type: "warning",
                message: "Zeiten unter 0 Stunden (negativ) können nicht erfasst werden."
            }));
        }

        if(timeInMinutes >= (24 * 60)) {
            timeInMinutes = 24 * 60;
            this.props.dispatch(SnackActions.showSnack({
                type: "warning",
                message: "Für ein Datum kann die erfasste Zeit nicht größer als 24 Stunden sein."
            }));
        }

        if(timeInMinutes%1 !== 0) {
            if(Math.round(timeInMinutes) > 24 * 60) {
                timeInMinutes = 24 * 60;
            }else if (Math.round(timeInMinutes) < 0) {
                timeInMinutes = 0;
            }else {                
                timeInMinutes = Math.round(timeInMinutes / 60) * 60;
            }
            this.props.dispatch(SnackActions.showSnack({
                type: "warning",
                message: "Zeiten können nur in 0,25-er Schritten eingegeben werden."
            }));
        }
        
        this.setState( {
            time: timeInMinutes
        });
        
    }

    handleSubmit(e) {
        e.preventDefault();
        const validity = e.target.checkValidity();
        
        if(validity || this.props.onSubmit){
            window.localStorage.setItem("lastSelectedMember", this.state.teamMemberId);
            window.localStorage.setItem("lastSelectedProject", this.state.projectId);
            this.props.onSubmit(this.state);
            this.setState(
                {
                    timeEntryId: 0,
                    projectId: "",
                    teamMemberId: "",
                    date: dateNow,
                    time: 0,
                    description: ""
                }
            )
        }
    }
}

export default connect()(TimeTrackingForm);