import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from "react-router";
import AuthenticationActions from "../actions/authenticationActions";
import SnackActions from "../actions/snackActions";
import Api from "../api/api";
import TokenStorage from "../utils/tokenStorage";
import {Alert, AlertTitle} from "@material-ui/lab";
import {CircularProgress} from "@material-ui/core";

class MagicLinkValidation extends Component {
    constructor(props){
        super(props);

        this.state = {
            pending: true,
            isValid: false
        }

        this.checkTokenValidity = this.checkTokenValidity.bind(this);
    }

    getToken() {
        const search = this.props.location.search;
        if(search && search !== ""){
            return new URLSearchParams(search).get("token");
        }
    }

    checkTokenValidity() {
        let api = new Api();
        const token = this.getToken();
        const history = this.props.history;
        this.setState( { pending: true });
        if(token) {
            api.Auth.loginWithMagicLinkToken(token)
                .then(() => {
                    this.props.dispatch(SnackActions.showSnack({
                        message: "Du wurdest erfolgreich angemeldet.",
                        type: "success"
                    }));
                    this.setState({ isValid: true, pending: false });
                    history.push("/dashboard");
                })
                .catch(() => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Die Anmeldung ist fehlgeschlagen."
                    }));
                    this.setState({ isValid: false, pending: false });
                })
        }else{
            this.setState({ isValid: false, pending: false });
        }
    }

    componentDidMount() {
        if(TokenStorage.isAuthenticated())
            this.props.history.push("/dashboard");

        this.checkTokenValidity();
    }

    render() {
        console.log(this.state.pending, this.state.isValid);
        if(!this.state.pending && !this.state.isValid) {
            return (
                <Alert severity="error">
                    <AlertTitle>MagicLink-Token ist nicht gültig</AlertTitle>
                    <p>Der verwendete MagicLink-Token ist fehlerhaft oder nicht mehr gültig. Bitte beantragen Sie einen neuen MagicLink.</p>
                    <Link to="/auth/magicLink" className="btn btn-primary btn-block">Neuen MagicLink beantragen</Link>
                </Alert>
            );
        }
        return (
            <CircularProgress />);
    }

}

export default connect()(MagicLinkValidation);
