import history from "../history.js";
export default class TokenStorage {

    static LOCAL_STORAGE_TOKEN = 'token';
    static LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

    static isAuthenticated() {
        return this.getToken() !== null;
    }

    static getAuthentication() {
        return 'Bearer ' + this.getToken();
    }

    static getPayload() {
        if(this.isAuthenticated()) {
            const token = this.getToken();
            let base64Payload = token.split('.')[1];
            let payload = Buffer.from(base64Payload, 'base64');
            return JSON.parse(payload.toString());
        }

        return null;
    }

    static getRole() {
        const payload = this.getPayload();
        if(payload){
            return payload.role;
        }
        return null;
    }

    static getUserId(){
        const payload = this.getPayload();
        if(payload){
            return payload.nameid;
        }
        return null;
    }

    static storeToken(token) {
        console.log(token);
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
    }

    static storeRefreshToken(refreshToken) {
        localStorage.setItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
    }

    static clear() {
        history.push("/signin");
        window.location.href = "https://pze.hhlab.io/signin"
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    static getRefreshToken() {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
    }

    static getToken() {
        return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    }
}
