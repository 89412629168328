import React, { Component } from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Api from "../api/api";
import ProjectEditButton from "./ProjectEditButton";
import ProjectDeleteButton from "./ProjectDeleteButton";
import './projectSettings.scss';
import ProjectCreateButton from "./ProjectCreateButton";
import ProjectArchiveButton from "./ProjectArchiveButton";

export class ProjectSettings extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projects: [],
            loading: true
        };
        
        this.loadProjects = this.loadProjects.bind(this);
    }
    
    componentDidMount() {
        this.loadProjects();
    }
    
    loadProjects() {
        this.setState({
            loading: true
        });
        
        const api = new Api(window.location.origin);
        api.Project.getAll()
            .then((data) => {
                this.setState(
                    {
                        projects: data,
                        loading: false
                    }
                );
            })
            .catch((data) => {
                this.setState({
                    loading: false
                });
            })
    }

    render () {
        return (
            <div className="ProjectSettings">
                <h3>
                   Projekte 
                </h3>
                <div>
                    <div className="ProjectSettings__actions">
                        <ProjectCreateButton saveAction={this.loadProjects} />
                    </div>
                    <div>
                        <TableContainer style={{ minHeight: "400px", height: "auto", maxHeight: "600px" }} component={Paper}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead className="ProjectSettings__table-header">
                                    <TableRow>
                                        <TableCell>Projekt</TableCell>
                                        <TableCell>Projekt-Typ</TableCell>
                                        <TableCell>Verantwortlicher</TableCell>
                                        <TableCell  align="right">Aktionen</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.state.loading ?
                                        <div>
                                            Lädt...
                                        </div>
                                        :
                                        this.state.projects.length > 0 ?                                            
                                            this.state.projects.map((row) => (
                                                <TableRow key={row.projectId}>
                                                    <TableCell component="th" scope="row">
                                                        {row.name} { row.isArchived ? <strong>(Archiviert)</strong> : "" }
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        { row.isExternalProject ? "externes Projekt" : "internes Projekt" }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.projectManagerName}
                                                    </TableCell>
                                                    <TableCell align="right" className="ProjectSettings__table-action">
                                                        <ProjectArchiveButton project={row} archiveAction={this.loadProjects} />
                                                        <ProjectDeleteButton project={row} deleteAction={this.loadProjects} />
                                                        <ProjectEditButton project={row} saveAction={this.loadProjects} />                                                        
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            <div>
                                                Keine Projekte gefunden
                                            </div>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        );
    }
}
