import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Api from "../api/api";
import {ProjectDeleteButton} from "./ProjectDeleteButton";
import {ProjectEditButton} from "./ProjectEditButton";

export class MonthStatistic extends Component {
    constructor(props) {
        super(props);        
    }    

    render () {
        const months = [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ];
        
        return (
            <div className="MonthStatistic">
                <Paper elevation={3}>
                    <h3 style={{ padding: "10px"}}>Monat</h3>
                    <div>
                        <TableContainer style={{ minHeight: "200px", height: "auto", maxHeight: "450px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead className="MonthStatistic__table-header">
                                    <TableRow>
                                        <TableCell>Monat</TableCell>
                                        <TableCell>Summe der erfassten Zeit (h)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.months.length > 0 ?
                                            this.props.months.map((row) => (
                                                <TableRow key={row.monthNumber}>
                                                    <TableCell component="th" scope="row">
                                                        { months[row.monthNumber - 1] }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.sum / 60}
                                                    </TableCell>                                                   
                                                </TableRow>
                                            ))
                                            :
                                            <div>
                                                Keine Daten
                                            </div>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>                        
                    </div>
                </Paper>
            </div>
        );
    }
}
