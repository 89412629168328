export const CHANGE_USER = "authentication/CHANGE_USER";
export const CLEAR_USER = "authentication/CLEAR_USER";
export const LOGOUT = "authentication/LOGOUT";

export default class AuthenticationActions {

    static changeUser(data) {
        return { type: CHANGE_USER, data };
    }

    static clearUser() {
        return { type: CLEAR_USER };
    }

    static logout() {
        return { type: LOGOUT };
    }
}
