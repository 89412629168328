import React, { Component } from 'react';
import Api from "../api/api";
import TimeEntryMultiForm from "./TimeEntryMultiForm";
import './timeTrackingMulti.scss';
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";
import {Layout} from "./Layout";

class TimeTrackingMulti extends Component {
    static displayName = TimeTrackingMulti.name;

    constructor (props) {
        super(props);
                
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    

    handleSubmit(e) {
        let timeEntries = [];
        const projectId = e.projectId;
        const teamMemberId = e.teamMemberId;

        e.timeEntries.forEach((entry) => {
            timeEntries.push(
                {
                    projectId: projectId,
                    teamMemberId: teamMemberId,
                    timeInMinutes: entry.time,
                    date: entry.date,
                    description: entry.description
                }
            )
        });

        const api = new Api();
        api.TimeEntry.createMulti(timeEntries)
            .then((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "success",
                    message: "Zeiten wurden erfolgreich erfasst."
                }));
            })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Zeiten konnten nicht erfasst werden."
                }));
            })
        
    }
    render () {
        return (
            <Layout className="TimeTrackingMulti">
                <div className="TimeTrackingMulti__header">
                    <h1>Mehrere Zeiten erfassen</h1>
                </div>
                <div>
                    <TimeEntryMultiForm store={this.props.store} onSubmit={this.handleSubmit} showButton={true} />
                </div>
            </Layout>
        );
    }
}

export default connect()(TimeTrackingMulti);
