import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class TeamMemberDeleteButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        let api = new Api();
        api.TeamMember.delete(this.props.teamMember.teamMemberId)
            .then(() => {                    
                this.props.deleteAction();
                this.handleClose();
                this.props.dispatch(SnackActions.showSnack({
                    type: "success",
                    message: "Teammitglied wurde gelöscht."
                }));

            })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Teammitglied konnte nicht angelegt werden."
                }));
            })      
    }

    render () {
        return (
            <div>
                <IconButton variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    <DeleteIcon />
                </IconButton>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"sm"} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Teammitglied <b>{ this.props.teamMember.name }</b> löschen
                    </DialogTitle>
                    <DialogContent dividers>
                        Möchtest du das Teammitglied <b>{this.props.teamMember.name}</b> und alle dazu erfassten Zeiten wirklich löschen?
                    </DialogContent>
                    <DialogActions>
                        <Button  color="primary" onClick={this.handleSubmit}>
                            Teammitglied endgültig löschen
                        </Button>
                        <Button  color="secondary" onClick={this.handleClose}>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect()(TeamMemberDeleteButton);