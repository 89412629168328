import axios from 'axios';
import TokenStorage from '../utils/tokenStorage';

export default class Http {

    static get(path, config = {}) {
        return Http.request({
            url: `/api${path}`,
            method: 'get',
            ...config,
        });
    }

    static post(path, data, config = {}) {
        return Http.request({
            url: `/api${path}`,
            method: 'post',
            data,
            ...config,
        });
    }

    static put(path, data, config = {}) {
        return Http.request({
            url: `/api${path}`,
            method: 'put',
            data,
            ...config,
        });
    }

    static delete(path, config = {}) {
        return Http.request({
            url: `/api${path}`,
            method: 'delete',
            ...config,
        });
    }

    static request(requestConfig) {
        axios.interceptors.request.use( config => {
                const fullAuthorizationTokenString = TokenStorage.getAuthentication();
                if(fullAuthorizationTokenString) {
                    config.headers['Authorization'] = fullAuthorizationTokenString;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error)
            });

        axios.interceptors.response.use((response) => {
                return response;
            },
            (error) => {
                const originalRequest = error.config;
                if(error.response.status === 401 && !originalRequest._retry && error.response.headers['token-expired'] === 'true') {
                    originalRequest._retry = true;
                    return Http.postWithoutToken('/auth/refresh',
                        {
                            "jwtToken": TokenStorage.getToken(),
                            "refreshToken": TokenStorage.getRefreshToken()
                        })
                        .then((res) => {
                            if (res.status >= 200 || res.status < 300) {
                                TokenStorage.storeToken(res.data.jwtToken);
                                TokenStorage.storeRefreshToken(res.data.refreshToken);

                                return Http.request(originalRequest);
                            }
                        }).catch(() => {
                            TokenStorage.clear();
                            return Promise.reject(error);
                        })
                }else{
                    return Promise.reject(error);
                }
            });

        return new Promise((resolve, reject) => {
            axios
                .request(requestConfig)
                .then(response => resolve(response.data))
                .catch(error => reject(error.response));
        });
    }

    static getWithoutToken(path, config = {}) {
        return Http.requestWithoutToken({
            url: `/api${path}`,
            method: 'get',
            ...config,
        });
    }

    static postWithoutToken(path, data, config = {}) {
        return Http.requestWithoutToken({
            url: `/api${path}`,
            method: 'post',
            data,
            ...config,
        });
    }

    static putWithoutToken(path, data, config = {}) {
        return Http.requestWithoutToken({
            url: `/api${path}`,
            method: 'put',
            data,
            ...config,
        });
    }

    static deleteWithoutToken(path, config = {}) {
        return Http.requestWithoutToken({
            url: `/api${path}`,
            method: 'delete',
            ...config,
        });
    }

    static requestWithoutToken(config) {
        return new Promise((resolve, reject) => {
            axios
                .request(config)
                .then(response => resolve(response.data))
                .catch(error => reject(error.response));
        });
    }

}
