import React, { Component } from 'react';
import {Grid, Paper} from "@material-ui/core";
import {ProjectSettings} from "./ProjectSettings";
import {TeamMemberSettings} from "./TeamMemberSettings";
import './settings.scss';
import {Layout} from "./Layout";
import TokenStorage from "../utils/tokenStorage";
import TeamMemberEditButton from "./TeamMemberEditButton";
import Api from "../api/api";

export class Settings extends Component {
    static displayName = Settings.name;
    constructor(props) {
        super(props);
        this.state = {
            teamMember: {
                teamMemberId: 0,
                name: "",
                email: "",
                password: ""
            }
        };
    }
    
    componentDidMount() {
        let api = new Api();
        api.TeamMember.getById(TokenStorage.getUserId())
            .then(data => {
                this.setState({
                   teamMember: data 
                });
            })
    }

    render () {
        return (
            <Layout className="Settings">
                <div className="Settings__header">
                    <h1>
                        Einstellungen
                    </h1>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined" >
                            <ProjectSettings />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper variant="outlined" >
                            <TeamMemberSettings />
                        </Paper>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}
