import React  from "react";
import TokenStorage from "../utils/tokenStorage";
import {Redirect, Route} from "react-router";

export const PrivateRoute = ({component: Component, ...data}) => (
    <Route
        {...data}
        render={props => (TokenStorage.isAuthenticated()) ?
            <Component {...props} />
            :
            (<Redirect to={{ pathname: "/signin", state: { from: props.location }}} />)
        }
    />
)
