import React, { Component } from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";

export class ProjectStatistic extends Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div className="ProjectStatistic" >
                <Paper elevation={3}>
                    <h3 style={{ padding: "10px"}}>Projekte</h3>
                    <div>
                        <TableContainer style={{ minHeight: "200px", height: "auto", maxHeight: "450px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead className="ProjectStatistic__table-header">
                                    <TableRow>
                                        <TableCell>Projekt</TableCell>
                                        <TableCell>Summe der erfassten Zeit (h)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        this.props.projects.length > 0 ?
                                            this.props.projects.map((row) => (
                                                <TableRow key={row.projectId}>
                                                    <TableCell component="th" scope="row">
                                                        {row.projectName}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.sum / 60}
                                                    </TableCell>                                                   
                                                </TableRow>
                                            ))
                                            :
                                            <div>
                                                Keine Daten
                                            </div>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>                        
                    </div>
                </Paper>
            </div>
        );
    }
}
