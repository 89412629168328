import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@material-ui/core";
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class TeamMemberCreateButton extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showDialog: false,
            teamMemberName: "", 
            teamMemberEmail: "",
            teamMemberPassword: ""
        };
        
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        const createForm = document.getElementById("createTeamMemberForm");        
        if(createForm.checkValidity()) {
            let api = new Api();
            api.TeamMember.create(
                {
                    user: {
                        name: this.state.teamMemberName,
                        email: this.state.teamMemberEmail,
                        passwordHashed: ""
                    },
                    passwordUnhashed: this.state.teamMemberPassword                    
                }
            )
                .then((data) => {
                    this.props.saveAction();
                    this.handleClose();
                    this.props.dispatch(SnackActions.showSnack({
                        type: "success",
                        message: "Benutzeraccount wurde erfolgreich angelegt."
                    }));
                    this.setState({
                        teamMemberName: "",
                        email: "",
                        password: ""
                    })
                })
                .catch((data) => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Teammitglied konnte nicht angelegt werden."
                    }));
                })
        }
    }

    handleNameChange(e) {
        this.setState(
            {
                teamMemberName: e.target.value
            }
        );
    }

    handleEmailChange(e) {
        this.setState(
            {
                teamMemberEmail: e.target.value
            }
        );
    }

    handlePasswordChange(e) {
        this.setState(
            {
                teamMemberPassword: e.target.value
            }
        );
    }
    
    render () {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    Benutzeraccount anlegen
                </Button>                
                    <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"sm"} fullWidth>
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            Benutzeraccount anlegen
                        </DialogTitle>
                        <DialogContent dividers>
                            <form id="createTeamMemberForm">
                                <TextField placeholder="Vor- und Nachname eingeben..." 
                                           InputLabelProps={{
                                                shrink: true,
                                           }} 
                                           margin="normal" 
                                           fullWidth 
                                           value={this.state.teamMemberName} 
                                           required
                                           label="Vor- und Nachname" 
                                           variant="outlined"
                                           name="name"
                                           onChange={this.handleNameChange}/>
                                <TextField placeholder="E-Mailadresse eingeben..."
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           margin="normal"
                                           fullWidth
                                           value={this.state.teamMemberEmail}
                                           required
                                           label="E-Mailadresse"
                                           variant="outlined"
                                           name="email"
                                           type="email"
                                           onChange={this.handleEmailChange}/>
                                <TextField placeholder="Passwort eingeben..."
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           margin="normal"
                                           fullWidth
                                           value={this.state.teamMemberPassword}
                                           required
                                           label="Passwort"
                                           variant="outlined"
                                           name="password"
                                           type="password"
                                           onChange={this.handlePasswordChange}/>
                              
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button  color="primary" onClick={this.handleSubmit}>
                                Benutzeraccount erstellen
                            </Button>
                            <Button  color="secondary" onClick={this.handleClose}>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }
}

export default connect()(TeamMemberCreateButton);