import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@material-ui/core";
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class ProjectArchiveButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        let api = new Api();
        if(!this.props.project.isArchived) {
            api.Project.archive(this.props.project.projectId)
                .then(() => {
                    this.props.archiveAction();
                    this.handleClose();
                    this.props.dispatch(SnackActions.showSnack({
                        type: "success",
                        message: "Projekt wurde archiviert."
                    }));
                })
                .catch(() => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Projekt konnte nicht archiviert werden."
                    }));
                })
        }else {
            api.Project.unarchive(this.props.project.projectId)
                .then(() => {
                    this.props.archiveAction();
                    this.handleClose();
                    this.props.dispatch(SnackActions.showSnack({
                        type: "success",
                        message: "Archivierung des Projekts wurde aufgehoben."
                    }));
                })
                .catch(() => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Archivierung des Projekts konnte nicht aufgehoben werden."
                    }));
                })
        }
    }

    render () {
        return (
            <div>
                <IconButton variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    {
                        !this.props.project.isArchived ?
                            <ArchiveIcon/>
                            :
                            <UnarchiveIcon/>
                    }
                </IconButton>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"sm"} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        {
                            this.props.project.isArchived ? 
                                "Archivierung aufheben?"
                                :
                                "Projekt archivieren?"
                        }
                    </DialogTitle>
                    <DialogContent dividers>
                        {
                            !this.props.project.isArchived ?
                                <>Möchtest du das Projekt <b>{this.props.project.name}</b> archivieren? Es ist
                                    anschließend nicht mehr in den Auswertungen sichtbar und es können keine Zeiten mehr erfasst werden.</>
                                :
                                "Soll die Archivierung des Projekts aufgehoben werden? Es ist anschließend wieder in den Auswertungen sichtbar und Zeiten können wieder erfasst werden."
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button  color="primary" onClick={this.handleSubmit}>
                            {
                                !this.props.project.isArchived ? 
                                    "Projekt archivieren"
                                    :
                                    "Archivierung aufheben"
                            }
                        </Button>
                        <Button  color="secondary" onClick={this.handleClose}>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect()(ProjectArchiveButton);