import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Api from "../api/api";
import TokenStorage from "../utils/tokenStorage";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";

class HeaderUser extends Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout() {
        const api = new Api();
        api.Auth.logout();
        this.props.history.push("/");

    }

    render() {
        if(TokenStorage.isAuthenticated()) {
            return (
                <Button onClick={this.logout} color="inherit">Abmelden</Button>
            );
        }

        return (
            <Button component={Link} to={"/signin"} color="inherit">Anmelden</Button>
        );
    }

}

let mapStateToProps = (state) => {
    return {
        user: state.authentication
    };
};

let HeaderUserContainer = connect(mapStateToProps)(HeaderUser);
export default withRouter(HeaderUserContainer);
