import React, { Component } from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class ProjectSelect extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            projects: [],
            selectedProjectId: ""
        }
        
        this.loadProjects = this.loadProjects.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidMount() {
        this.loadProjects();
        this.setState(
            {
                selectedProjectId: this.props.value
            }
        );
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.setState(
                {
                    selectedProjectId: this.props.value
                }
            );
        }
    }

    loadProjects() {
        let api = new Api();
        
        if(this.props.withArchived) {
            api.Project.getAll()
                .then((data) => {
                    this.setState({
                        projects: data
                    })
                })
                .catch((data) => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Projekt-Auswahl konnte nicht geladen werden."
                    }));
                })
        }else {
            api.Project.getAllUnarchivedProjects()
                .then((data) => {
                    this.setState({
                        projects: data
                    })
                })
                .catch((data) => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Projekt-Auswahl konnte nicht geladen werden."
                    }));
                })
        }
        

    }

    handleChange(e) {
        this.setState({
            selectedProjectId: e.target.value
        });

        if(this.props.onChange)
            this.props.onChange(e.target.value);
    }
    
    render () {
        return (
            <FormControl required={this.props.required} size={this.props.size} variant="outlined" fullWidth>
                <InputLabel id="project-label">Projekt</InputLabel>
                <Select
                    labelId="project-label"
                    required={this.props.required}
                    id="project-select"
                    value={this.state.selectedProjectId}
                    onChange={this.handleChange}
                    label="Projekt"
                >
                    <MenuItem value="">
                        <em>Kein Projekt ausgewählt</em>
                    </MenuItem>
                    {
                        this.state.projects.map((project) => {
                            return (<MenuItem key={project.projectId} value={project.projectId}>{project.name}</MenuItem>);
                        })
                    }
                </Select>
            </FormControl>
        );
    }
}

export default connect()(ProjectSelect);