import React, { Component } from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

export class MonthPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMonth: ""
        }

        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(e) {
        this.setState({
            selectedMonth: e.target.value
        });

        if(this.props.onChange)
            this.props.onChange(e.target.value);
    }
    
    render () {
        const months = [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ];
        
        return (
            <div className={"MonthPicker " + this.props.className}>
                <FormControl size={this.props.size} variant="outlined" fullWidth>
                    <InputLabel id="monthPicker-label">Monat</InputLabel>
                    <Select
                        labelId="monthPicker-label"
                        id="monthPicker"
                        value={this.state.selectedMonth}
                        onChange={this.handleChange}
                        label="Monat"
                    >
                        <MenuItem value="">
                            <em>Kein Monat ausgewählt</em>
                        </MenuItem>
                        {
                            months.map((month, index) => {
                                return (
                                    <MenuItem key={index} value={index+1}>{month}</MenuItem>
                                );
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        );
    }
}
