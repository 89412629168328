export const SHOW_SNACK = "snack/SHOW_SNACK";
export const HIDE_SNACK = "snack/HIDE_SNACK";

export default class SnackActions {

    static showSnack(data){
        return { type: SHOW_SNACK, data };
    }

    static hideSnack() {
        return { type: HIDE_SNACK };
    }
}
