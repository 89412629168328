import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@material-ui/core";
import Api from "../api/api";
import TimeEntryMultiForm from "./TimeEntryMultiForm";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class TimeEntryCreateMultiButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        console.log(e);
        let timeEntries = [];
        const projectId = e.projectId;
        const teamMemberId = e.teamMemberId;

        e.timeEntries.forEach((entry) => {
            timeEntries.push(
                {
                    projectId: projectId,
                    teamMemberId: teamMemberId,
                    timeInMinutes: entry.time,
                    date: entry.date,
                    description: entry.description
                }
            )
        });

        const api = new Api();
        api.TimeEntry.createMulti(timeEntries)
            .then((data) => {
                this.props.saveAction();
                this.props.dispatch(SnackActions.showSnack({
                    type: "success",
                    message: "Zeiten wurden erfolgreich erfasst."
                }));
                this.handleClose();
            })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Zeiten konnten nicht erfasst werden."
                }));
            })

    }

    render () {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    Mehrere Zeiten erfassen
                </Button>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"md"} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Mehrere Zeiten erfassen
                    </DialogTitle>
                    <DialogContent dividers>
                        <TimeEntryMultiForm showButton={false} onSubmit={this.handleSubmit} id={"createMultiForm"}/>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleSubmitClick}>
                            Zeiten erfassen
                        </Button>
                        <Button  color="secondary" onClick={this.handleClose}>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    handleSubmitClick(e) {
        const createForm = document.getElementById("createMultiForm");
        createForm.dispatchEvent(
            new Event("submit", { cancelable: true })
        );
    }
}

export default connect()(TimeEntryCreateMultiButton);