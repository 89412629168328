import React, { Component } from 'react';
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    TextField
} from "@material-ui/core";
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

export class ProjectCreateButton extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          showDialog: false,
          project: {
              name: "",
              projectManagerName: "",
              isExternalProject: false
          }  
        };
        
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleProjectManagerNameChange = this.handleProjectManagerNameChange.bind(this);
        this.handleIsExternalProjectChange = this.handleIsExternalProjectChange.bind(this);
    }

    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        const createForm = document.getElementById("createForm");
        
        if(createForm.checkValidity()) {
            let api = new Api();
            api.Project.create(this.state.project)
                .then((data) => {
                    this.props.saveAction();
                    this.handleClose();
                    this.props.dispatch(SnackActions.showSnack({
                        type: "success",
                        message: "Projekt wurde erfolgreich angelegt."
                    }));
                    this.setState({
                        project: {
                            name: "",
                            projectManagerName: ""
                        }
                    });

                })
                .catch((data) => {
                    this.props.dispatch(SnackActions.showSnack({
                        type: "error",
                        message: "Projekt konnte nicht angelegt werden."
                    }));
                })
        }
    }

    handleIsExternalProjectChange(e) {
        let project = this.state.project;

        this.setState(
            {
                project: {
                    name: project.name,
                    projectManagerName: project.projectManagerName,
                    isExternalProject: e.target.checked
                }
            }
        );
    }

    handleNameChange(e) {
        let project = this.state.project;
        
        this.setState(
            {
                project: {
                    name: e.target.value,
                    projectManagerName: project.projectManagerName,
                    isExternalProject: project.isExternalProject
                }
            }
        );
    }

    handleProjectManagerNameChange(e) {
        let project = this.state.project;

        this.setState(
            {
                project: {
                    name: project.name,
                    projectManagerName: e.target.value,
                    isExternalProject: project.isExternalProject
                }
            }
        );
    }

    render () {
        console.log(this.props.store)
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    Projekt anlegen
                </Button>                
                    <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"sm"} fullWidth>
                        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                            Projekt erstellen
                        </DialogTitle>
                        <DialogContent dividers>
                            <form id="createForm">
                                <TextField placeholder="Projektname eingeben..." 
                                           InputLabelProps={{
                                                shrink: true,
                                           }} 
                                           margin="normal" 
                                           fullWidth 
                                           value={this.state.project.name} 
                                           required
                                           label="Projektname" 
                                           variant="outlined"
                                           name="name"
                                           onChange={this.handleNameChange}/>
                                <TextField placeholder="Projektverantwortlichen eingeben..."
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           margin="normal" 
                                           fullWidth 
                                           value={this.state.project.projectManagerName} 
                                           label="Projektverantworlicher" 
                                           variant="outlined"
                                           name="projectManagerName"
                                           onChange={this.handleProjectManagerNameChange}/>
                                <FormControlLabel control={<Checkbox checked={this.state.project.isExternalProject} onChange={this.handleIsExternalProjectChange} />} label="Externes Projekt" />
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button  color="primary" onClick={this.handleSubmit}>
                                Projekt erstellen
                            </Button>
                            <Button  color="secondary" onClick={this.handleClose}>
                                Abbrechen
                            </Button>
                        </DialogActions>
                    </Dialog>
            </div>
        );
    }
}

export default connect()(ProjectCreateButton);