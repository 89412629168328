import React, { Component } from 'react';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import Api from "../api/api";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class TeamMemberSelect extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            teamMembers: [],
            selectedMemberId: ""
        }
        
        this.loadTeamMembers = this.loadTeamMembers.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidMount() {
        this.loadTeamMembers();
        this.setState(
            {
                selectedMemberId: this.props.value
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.setState(
                {
                    selectedMemberId: this.props.value
                }
            );
        }
    }

    loadTeamMembers() {
        let api = new Api();
        api.TeamMember.getAllNames()
            .then((data) => {
                this.setState({
                    teamMembers: data
                })
            })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Teammitglieder-Auswahl konnte nicht geladen werden."
                }));
            })
    }

    handleChange(e) {
        this.setState({
            selectedMemberId: e.target.value
        });

        if(this.props.onChange)
            this.props.onChange(e.target.value);
    }
    
    render () {
        return (
            <FormControl required={this.props.required} size={this.props.size} variant="outlined" fullWidth>
                <InputLabel id="member-label">Teammitglied</InputLabel>
                <Select
                    required={this.props.required}
                    labelId="member-label"
                    id="member-select"
                    value={this.state.selectedMemberId}
                    onChange={this.handleChange}
                    label="Teammitglied"
                >
                    <MenuItem value="">
                        <em>Kein Teammitglied ausgewählt</em>
                    </MenuItem>
                    {
                        this.state.teamMembers.map((member) => {
                            return (<MenuItem key={member.teamMemberId} value={member.teamMemberId}>{member.name}</MenuItem>);
                        })
                    }
                </Select>
            </FormControl>
        );
    }
}

export default connect()(TeamMemberSelect);