import React, { Component } from 'react';
import './NavMenu.scss';
import {AppBar, Button, Hidden, IconButton, Toolbar, Typography} from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import ListIcon from '@material-ui/icons/List';
import DarkModeActivateIcon from '@material-ui/icons/Brightness4';
import DarkModeDeactivateIcon from '@material-ui/icons/Brightness5';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import {Link} from "react-router-dom";
import HeaderUser from "./HeaderUser";
import TokenStorage from "../utils/tokenStorage";
const prefersDarkMode = window.localStorage.getItem("use-darkMode");

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);
  }

  render () {
    return (
      <header>
        <AppBar position="static" className="NavBar">
          <Toolbar className="NavBar__Toolbar">
            <Hidden smDown={true}>
              <Typography variant="h6">
                <Link to="/">
                  Projektzeiterfassung
                </Link>
              </Typography>
            </Hidden>
              <div className="NavBar__menu">
                { TokenStorage.isAuthenticated() ?
                <>
                  <Button
                      aria-label="Projekt"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      component={Link} to="/tracking"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    <Hidden smDown={true}>
                      Zeit erfassen
                    </Hidden>
                  </Button>
                  <Button
                      aria-label="Projekt"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      component={Link} to="/filter"
                      startIcon={<ListIcon />}
                  >
                    <Hidden smDown={true}>
                      Daten
                    </Hidden>
                  </Button>
                  <Button
                      aria-label="Projekt"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      component={Link} to="/dashboard"
                      startIcon={<AssessmentOutlinedIcon />}
                  >
                    <Hidden smDown={true}>
                      Auswertung
                    </Hidden>
                  </Button>
                  <Button
                      aria-label="Settings"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      color="inherit"
                      component={Link} to="/settings"
                      startIcon={<SettingsIcon />}
                  >
                    <Hidden smDown={true}>
                      Einstellungen
                    </Hidden>
                  </Button>
                </>
                :
                null}                
                <HeaderUser />
                <Button
                    aria-label="Darkmode"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={this.toggleDarkMode}
                >
                  {!prefersDarkMode ? <DarkModeActivateIcon fontSize={"small"} /> : <DarkModeDeactivateIcon fontSize={"small"} />}
                </Button>
              </div>
          </Toolbar>
        </AppBar>
      </header>
    );
  }

  toggleDarkMode() {
    let isDarkmode = window.localStorage.getItem("use-darkMode"); 
    
    if(isDarkmode) {
      window.localStorage.removeItem("use-darkMode");
    }else {
      window.localStorage.setItem("use-darkMode", "true");
    }

    window.location.reload();
  }
}
