import React, { Component } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ProjectSelect from "./ProjectSelect";
import TeamMemberSelect from "./TeamMemberSelect";
import {
    Button,
    FormControl,
    Grid, Hidden, InputLabel, MenuItem,
    Paper, Select,
    TextField, Tooltip
} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './analyzer.scss';
import Api from "../api/api";
import TimeEntryEditButton from "./TimeEntryEditButton";
import TimeEntryDeleteButton from "./TimeEntryDeleteButton";
import TimeEntryCreateButton from "./TimeEntryCreateButton";
import {DataGrid, deDE, GridToolbarContainer, GridToolbarExport} from '@material-ui/data-grid';
import TimeEntryCreateMultiButton from "./TimeEntryCreateMultiButton";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";
import {Layout} from "./Layout";
let moment = require('moment');

function ExportToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

class Analyzer extends Component {
    static displayName = Analyzer.name;
    
    constructor(props) {
        super(props);
        
        this.state = {
            timeEntries: [],
            selectedProjectId: "",
            selectedTeamMemberId: "",
            selectedDate: "",
            selectedProjectType: "all",
            dateRelation: "EQUAL",
            showFilter: true,
            loading: true
        };
        
        this.loadAllTimeEntries = this.loadAllTimeEntries.bind(this);
        this.handleProjectSelect = this.handleProjectSelect.bind(this);
        this.handleTeamMemberSelect = this.handleTeamMemberSelect.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
        this.handleDateRelationChange = this.handleDateRelationChange.bind(this);
        this.handleToggleFilter = this.handleToggleFilter.bind(this);
        this.handleProjectTypeChange = this.handleProjectTypeChange.bind(this);
        
    }
    
    componentDidMount() {
        this.loadAllTimeEntries();
    }
    
    loadAllTimeEntries(projectId = this.state.selectedProjectId, teamMemberId = this.state.selectedTeamMemberId, date = this.state.selectedDate, dateRelation = this.state.dateRelation, projectType = this.state.selectedProjectType) {
        this.setState({
            loading: true
        });
        
        let api = new Api();
        api.TimeEntry.filter(
            projectId !== undefined ? projectId: this.state.selectedProjectId, 
            teamMemberId !== undefined ? teamMemberId :this.state.selectedTeamMemberId, 
            date !== undefined ? date : this.state.selectedDate, 
            dateRelation !== undefined ? dateRelation : this.state.dateRelation,
            projectType !== undefined ? projectType : this.state.selectedProjectType)
            .then((data) => {
                console.log(data);
                this.setState({
                    loading: false,
                    timeEntries: data.map((value) => {
                        return {
                            id: value.timeEntryId,
                            date: moment(value.date).format("DD.MM.yyyy"),
                            time: value.timeInMinutes / 60,
                            projectName: value.project.name,
                            teamMemberName: value.teamMember.name,
                            description: value.description,
                            actions: ""
,                            fullData: value
                        }
                    })
                });
            })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Daten konnten nicht geladen werden."
                }));
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.loadAllTimeEntries();
    }

    handleProjectSelect(projectId) {
        this.setState({
            selectedProjectId: projectId
        });
    }

    handleTeamMemberSelect(memberId) {
        this.setState({
            selectedTeamMemberId: memberId
        });
    }

    handleDateChange(e) {
        this.setState(
            {
                selectedDate: e.target.value
            }
        );
    }

    handleDateRelationChange(e) {
        this.setState(
            {
                dateRelation: e.target.value
            }
        );
    }
    
    render () {
        const theme = createMuiTheme(
            deDE
        );
        
        return (
            <Layout className="Analyzer">
                <div className="Analyzer__header">
                    <h1>
                        Daten <small>(Erfasste Zeiten)</small>
                    </h1>
                    <div>
                        <Hidden smDown={true}>
                            <TimeEntryCreateButton saveAction={this.loadAllTimeEntries} />
                            <TimeEntryCreateMultiButton saveAction={this.loadAllTimeEntries}/>
                        </Hidden>
                    </div>
                </div>
                <Paper className="Analyzer__filter" elevation={3}>
                    <div className="Analyzer__filter-header" onClick={this.handleToggleFilter}>
                        {
                            this.state.showFilter ?
                                <ExpandLessIcon />
                                :
                                <ExpandMoreIcon />
                        }
                        <h3>Filter</h3>
                    </div>    
                    { this.state.showFilter ?
                        <form onSubmit={this.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <ProjectSelect
                                        withArchived
                                        size="small"
                                        value={this.state.selectedProjectId}
                                        onChange={this.handleProjectSelect}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TeamMemberSelect
                                        size="small"
                                        value={this.state.selectedTeamMemberId}
                                        onChange={this.handleTeamMemberSelect}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        size="small"
                                        id="date-input"
                                        label="Datum"
                                        type="date"
                                        onChange={this.handleDateChange}
                                        value={this.state.selectedDate}
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl required={this.props.required} variant="outlined"  size="small"fullWidth>
                                        <InputLabel id="dateRelation-label">Datum-Operator</InputLabel>
                                        <Select
                                            required={this.props.required}
                                            labelId="dateRelation-label"
                                            id="dateRelation-select"
                                            value={this.state.dateRelation}
                                            onChange={this.handleDateRelationChange}
                                            label="Datum-Operator"
                                        >
                                            <MenuItem value="EQUAL">gleich</MenuItem>
                                            <MenuItem value="GREATER">größer und gleich</MenuItem>
                                            <MenuItem value="LESSER">kleiner und gleich</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl variant="outlined"  size="small"fullWidth>
                                        <InputLabel id="projectType-label">Projekt-Typ</InputLabel>
                                        <Select
                                            required={this.props.required}
                                            labelId="projectType-label"
                                            id="projectType-select"
                                            value={this.state.selectedProjectType}
                                            onChange={this.handleProjectTypeChange}
                                            label="Datum-Operator"
                                        >
                                            <MenuItem defaultChecked={true} value="all">Alle Projekte</MenuItem>
                                            <MenuItem value="INTERN">Interne Projekte</MenuItem>
                                            <MenuItem value="EXTERNAL">Externe Projekte</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid className="Analyzer__filter-actions" item xs={12}>
                                    <Button variant="outlined" type="submit" color="primary">
                                        Filtern
                                    </Button>
                                    <Button variant="outlined" onClick={this.handleDeleteFilter} color="primary">
                                        Alle Filter löschen
                                    </Button>
                                </Grid>
                            </Grid>                        
                        </form>
                        :
                        null 
                    }
                    
                </Paper>
                <Paper className="Analyzer__results" elevation={3}>
                    <h3>Ergebnisse</h3>
                    <div style={{ height: this.state.showFilter ? 500 : 700, width: '100%' }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                loading={this.state.loading}
                                disableColumnFilter
                                columns={
                                [
                                    {field: 'date', headerName: 'Datum', flex: 1, type: "date", sort: 'asc'},
                                    {field: 'time', headerName: 'Erfasste Zeit (h)', flex: 1, type: "number"},
                                    {field: 'projectName', headerName: 'Projekt', flex: 1},
                                    {field: 'teamMemberName', headerName: 'Teammitglied', flex: 1},
                                    {field: 'description', headerName: 'Anmerkung', flex: 1},
                                    {
                                        field: "actions",
                                        headerName: "Aktionen", 
                                        sortable: false,
                                        filterable: false,
                                        draggable: false,
                                        flex: 1,
                                        disableClickEventBubbling: true,
                                        renderCell: (params) => {
                                            return (<div style={{ display: "flex" }}>
                                                <Tooltip title={"Bearbeiten"}>
                                                    <TimeEntryEditButton timeEntry={params.row.fullData} saveAction={this.loadAllTimeEntries} />
                                                </Tooltip>
                                                <Tooltip title={"Löschen"}>
                                                    <TimeEntryDeleteButton timeEntry={params.row.fullData} deleteAction={this.loadAllTimeEntries} />
                                                </Tooltip>
                                            </div>);
                                        }                                
                                    },
                                ]
                            } 
                                rows={this.state.timeEntries}
                                components={{
                                    Toolbar: ExportToolbar
                                }}
                                localeText={{
                                    toolbarExport: 'Export',
                                    toolbarExportLabel: 'Export',
                                    toolbarExportCSV: 'Download als CSV'
                                }}
                            />
                        </ThemeProvider>
                    </div>   
                </Paper>
            </Layout>
        );
    }

    handleDeleteFilter() {
        this.setState({
            selectedProjectId: "",
            selectedTeamMemberId: "",
            selectedProjectType: "all",
            selectedDate: "",
            dateRelation: "EQUAL"
        });
        
        this.loadAllTimeEntries("", "", "", "EQUAL", "all");
    }

    handleToggleFilter() {
        this.setState({
            showFilter: !this.state.showFilter
        });
    }

    handleProjectTypeChange(e) {
        this.setState({
            selectedProjectType: e.target.value
        });
    }
}

export default connect()(Analyzer);
