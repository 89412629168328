import React, { Component } from 'react';
import TimeTrackingForm from "./TimeTrackingForm";
import Api from "../api/api";
import {Link} from "react-router-dom";
import './timeTracking.scss';
import {Button, Hidden} from "@material-ui/core";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";
import {Layout} from "./Layout";

class TimeTracking extends Component {
    static displayName = TimeTracking.name;

    constructor (props) {
        super(props);
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleSubmit(e) {
        let api = new Api();
        api.TimeEntry.create(
            {
                date: e.date,
                timeInMinutes: e.time,
                projectId: e.projectId,
                teamMemberId: e.teamMemberId,
                description: e.description
            }
        ).then((data) => {
            this.props.dispatch(SnackActions.showSnack({
                type: "success",
                message: "Zeit wurde erfolgreich erfasst."
            }));
        }).catch((data) => {
            this.props.dispatch(SnackActions.showSnack({
                type: "error",
                message: "Zeit konnte nicht erfasst werden."
            }));
        })
    }

    render () {
        return (
            <Layout className="TimeTracking">
                <div className="TimeTracking__header">
                    <h1>Zeit erfassen</h1>
                    <Hidden only="xs">
                        <Button variant="outlined" color="primary" component={Link} to={"/tracking/multi"}>
                            Mehrere Zeiten erfassen
                        </Button>
                    </Hidden>
                </div>
                <div>
                    <TimeTrackingForm store={this.props.store} onSubmit={this.handleSubmit} showButton={true} />
                </div>
            </Layout>
        );
    }
}

export default connect()(TimeTracking);
