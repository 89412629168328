import { combineReducers } from "redux";
import SnackReducer from "./snackReducer";
import AuthenticationReducer from "./authenticationResucer";

let reduce = combineReducers({
    authentication: AuthenticationReducer.reducer,
    snack: SnackReducer.reducer
});

export default reduce;
