import { createStore, applyMiddleware } from "redux";
import * as storage from "redux-storage";
import reducers from "../reducers/index";
import createEngine from 'redux-storage-engine-localstorage';

const reducer = storage.reducer(reducers);
const engine = createEngine('app:data');
const middleware = storage.createMiddleware(engine);
const createStoreWithMiddleware = applyMiddleware(middleware)(createStore);
const store = createStoreWithMiddleware(reducer);
const load = storage.createLoader(engine);
load(store);

export default store;
