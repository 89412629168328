import React, { Component } from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import Api from "../api/api";
import TimeTrackingForm from "./TimeTrackingForm";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";

class TimeEntryEditButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClickOpen() {
        this.setState(
            {
                showDialog: true
            }
        );
    }

    handleClose() {
        this.setState(
            {
                showDialog: false
            }
        );
    }

    handleSubmit(e) {
        console.log(e);
        let api = new Api();
        api.TimeEntry.update(
            {
                timeEntryId: e.timeEntryId,
                date: e.date,
                timeInMinutes: e.time,
                projectId: e.projectId,
                teamMemberId: e.teamMemberId,
                description: e.description
            }
        )
            .then((data) => {
                this.props.saveAction();
                this.handleClose();
                this.props.dispatch(SnackActions.showSnack({
                    type: "success",
                    message: "Zeit wurde erfolgreich gespeichert."
                }));
                })
            .catch((data) => {
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Zeit konnte nicht gespeichert werden."
                }));
            })
        
    }

    render () {
        return (
            <div className={"TimeEntryEditButton"}>
                <IconButton variant="outlined" color="primary" onClick={this.handleClickOpen}>
                    <EditIcon />
                </IconButton>
                <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.showDialog} maxWidth={"sm"} fullWidth>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                        Erfasste Zeit bearbeiten
                    </DialogTitle>
                    <DialogContent dividers>
                        <TimeTrackingForm timeEntry={this.props.timeEntry} id="editForm" showButton={false} onSubmit={this.handleSubmit}/>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={this.handleSubmitClick}>
                            Zeit speichern
                        </Button>
                        <Button  color="secondary" onClick={this.handleClose}>
                            Abbrechen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    handleSubmitClick(e) {
        const createForm = document.getElementById("editForm");
        createForm.dispatchEvent(
            new Event("submit", { cancelable: true })
        );
    }
}

export default connect()(TimeEntryEditButton);