import React, { Component } from 'react';
import {Button, Container, Grid, TextField, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import TokenStorage from "../utils/tokenStorage";
import Api from "../api/api";
import {connect} from "react-redux";
import { withRouter } from "react-router";
import SnackActions from "../actions/snackActions";
import './MagicLinkSignIn.scss';

class MagicLinkSignIn extends Component {
    static displayName = MagicLinkSignIn.name;
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            isSent: false
        }

        this.changeHandler = this.changeHandler.bind(this);
        this.sendMagicLink = this.sendMagicLink.bind(this);
    }

    componentDidMount() {
        if(TokenStorage.isAuthenticated())
            this.props.history.push("/");
    }

    changeHandler(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    sendMagicLink(event) {
        event.preventDefault();
        const api = new Api();
        const email = this.state.email;

        api.Auth.sendMagicLink(email)
            .then(() => {
                this.props.showSnack({
                    message: "MagicLink-Token wurde an deine E-Mailadresse gesendet.",
                    type: "success"
                });
                this.setState({ isSent: true });
            })
            .catch(() => {
                this.props.showSnack({
                    message: "MagicLink-Token konnte nicht gesendet werden.",
                    type: "error"
                })
            });
    }
    render () {
        return (
            <Container className="MagicLinkSignIn" component="main" maxWidth="xs">
                <header className="MagicLinkSignIn__header">
                    <Typography component="h1" variant="h5">
                        Projektzeiterfassung
                    </Typography>
                </header>
                <div  className="MagicLinkSignIn__content">
                    <Typography component="h2" variant="h6" className="MagicLinkSignIn__content-header">
                        Mit Magic-Link anmelden
                    </Typography>
                    <form onSubmit={this.sendMagicLink} className="MagicLinkSignIn__content-form">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-Mailadresse"
                            name="email"
                            onChange={this.changeHandler}
                            autoComplete="email"
                            autoFocus
                        />                      
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            MagicLink anfordern
                        </Button>
                    </form>
                    <div className="MagicLinkSignIn__content-alternative">
                        <Grid container>
                            <Grid item xs>
                                <h6>
                                    <span>
                                        oder
                                    </span>
                                </h6>
                            </Grid>
                        </Grid>
                        <Grid container className="SignIn__content-alternative-actions">
                            <Grid item xs>
                                <Link to="/signin" variant="body2">
                                    Mit Benutzername und Passwort anmelden.
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
        );
    }
}

let mapStateToProps = (state) => {
    return {};
}

let mapDispatchToProps = {
    showSnack: SnackActions.showSnack
}

let MagicLinkSingInForm = connect(mapStateToProps, mapDispatchToProps)(MagicLinkSignIn);
export default withRouter(MagicLinkSingInForm);

