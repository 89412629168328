import React, { Component } from "react";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";
import {Snackbar} from "@material-ui/core";
import { Alert } from '@material-ui/lab';

class AppSnackbar extends Component {
    
    render() {
            return (
                <Snackbar open={this.props.snack.isVisible} autoHideDuration={6000} onClose={this.props.hideSnack}>
                    <Alert elevation={6} variant="filled" onClose={this.props.hideSnack} severity={this.props.snack.type}>
                        { this.props.snack.message }
                    </Alert>
                </Snackbar>
            );
    }
}

let mapStateToProps = (state) => {
    return {
        snack: state.snack
    };
};

let mapDispatchToProps = {
    hideSnack: SnackActions.hideSnack
};

let AppSnackbarContainer = connect(mapStateToProps, mapDispatchToProps)(AppSnackbar);
export default AppSnackbarContainer;
