import Http from "./http";

export default class TeamMember {
    constructor(route = "/teammember") {
        this.route = route;
    }

    getById(id) {
        return Http.get(`${this.route}/${id}`);
    }

    getAll() {
        return Http.get(`${this.route}/all`);
    }

    getAllNames() {
        return Http.get(`${this.route}/names/all`);
    }

    create(teamMember) {
        console.log(teamMember);
        return Http.post(`${this.route}`, teamMember);
    }

    delete(teamMemberId) {
        return Http.delete(`${this.route}/${teamMemberId}`);
    }

    update(teamMemberId, teamMember) {
        console.log(teamMember);
        return Http.put(`${this.route}/${teamMemberId}`, teamMember);
    }

}