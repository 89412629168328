import React, { Component } from 'react';
import {
    Button, Checkbox,
    CircularProgress, FormControl, FormControlLabel,
    Grid, Hidden, InputLabel, MenuItem,
    Paper, Select
} from "@material-ui/core";
import {ProjectStatistic} from "./ProjectStatistic";
import {TeamMemberStatistic} from "./TeamMemberStatistic";
import {MonthStatistic} from "./MonthStatistic";
import './dashboard.scss';
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProjectSelect from "./ProjectSelect";
import TeamMemberSelect from "./TeamMemberSelect";
import TimeEntryCreateButton from "./TimeEntryCreateButton";
import TimeEntryCreateMultiButton from "./TimeEntryCreateMultiButton";
import YearPicker from "./YearPicker";
import {MonthPicker} from "./MonthPicker";
import Api from "../api/api";
import {StatisticGraph} from "./StatisticGraph";
import {connect} from "react-redux";
import SnackActions from "../actions/snackActions";
import {Layout} from "./Layout";

class Dashboard extends Component {
    static displayName = Dashboard.name;
    
    constructor(props) {
        super(props);
        
        this.state = {
            statistic: {
                projects: [],
                members: [],
                months: []
            },
            loading: true,
            showFilter: false,
            selectedProjectId: "",
            selectedTeamMemberId: "",
            showArchived: false,
            selectedYear:  new Date().getFullYear(),
            selectedMonth: "",
            selectedProjectType: "all"
            
        };
        
        this.handleProjectSelect = this.handleProjectSelect.bind(this);
        this.handleTeamMemberSelect = this.handleTeamMemberSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteFilter = this.handleDeleteFilter.bind(this);
        this.handleToggleFilter = this.handleToggleFilter.bind(this);
        this.handleYearSelect = this.handleYearSelect.bind(this);
        this.handleMonthSelect = this.handleMonthSelect.bind(this);
        this.loadStatistic = this.loadStatistic.bind(this);
        this.handleCheckArchived = this.handleCheckArchived.bind(this);
        this.handleProjectTypeChange = this.handleProjectTypeChange.bind(this);
    }
    
    componentDidMount() {
        this.loadStatistic();
    }

    loadStatistic(year = this.state.selectedYear, memberId = this.state.selectedTeamMemberId, projectId = this.state.selectedProjectId, month = this.state.selectedMonth, showArchived = this.state.showArchived, projectType = this.state.selectedProjectType) {
        this.setState({
            loading: true
        });
        
        const api = new Api();
        api.TimeEntry.getStatistic(year, memberId, projectId, month, showArchived, projectType)
            .then((data) => {
                this.setState({
                    statistic: data,
                    loading: false
                });
            })
            .catch(() => {
                this.setState({
                    loading: false
                });
                this.props.dispatch(SnackActions.showSnack({
                    type: "error",
                    message: "Daten konnten nicht geladen werden."
                }));
            })
    }

    handleSubmit(e) {
        e.preventDefault();
        
        this.loadStatistic();
    }

    handleProjectSelect(projectId) {
        this.setState({
            selectedProjectId: projectId
        });
    }

    handleMonthSelect(month) {
        this.setState({
            selectedMonth: month
        });
    }

    handleYearSelect(year) {
        this.setState({
            selectedYear: year
        });
        
        this.loadStatistic(year);
    }

    handleTeamMemberSelect(memberId) {
        this.setState({
            selectedTeamMemberId: memberId
        });
    }

    handleCheckArchived(e) {
        this.setState({
            showArchived: !this.state.showArchived
        });
    }

    handleDeleteFilter() {
        this.setState({
            selectedProjectId: "",
            selectedTeamMemberId: "",
            selectedMonth: "",
            selectedProjectType: "all"
        });
        
        this.loadStatistic(this.state.selectedYear, "", "", "", false, "all")
    }

    handleToggleFilter() {
        this.setState({
            showFilter: !this.state.showFilter
        });
    }

    handleProjectTypeChange(e) {
        this.setState({
            selectedProjectType: e.target.value
        });
    }

    render () {
        console.log(this.state);
        return (
            <Layout className="Dashboard">
                <div>
                    <div className="Dashboard__header">
                        <h1>Auswertung für <YearPicker className="Dashboard__yearPicker" onChange={this.handleYearSelect} /></h1>
                        <div className="Dashboard__header-actions">
                            <Hidden smDown={true}>
                                <TimeEntryCreateButton saveAction={this.loadStatistic} />
                                <TimeEntryCreateMultiButton saveAction={this.loadStatistic}/>
                            </Hidden>
                        </div>
                    </div>                    
                    <Paper className="Dashboard__filter" elevation={3}>
                        <div className="Dashboard__filter-header" onClick={this.handleToggleFilter}>
                            {
                                this.state.showFilter ?
                                    <ExpandLessIcon />
                                    :
                                    <ExpandMoreIcon />
                            }
                            <h3>Filter</h3>
                        </div>
                        { this.state.showFilter ?
                            <form onSubmit={this.handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <ProjectSelect
                                            size="small"
                                            value={this.state.selectedProjectId}
                                            onChange={this.handleProjectSelect}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TeamMemberSelect
                                            size="small"
                                            value={this.state.selectedTeamMemberId}
                                            onChange={this.handleTeamMemberSelect}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MonthPicker
                                            size="small"
                                            value={this.state.selectedMonth}
                                            onChange={this.handleMonthSelect}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl variant="outlined"  size="small"fullWidth>
                                            <InputLabel id="projectType-label">Projekt-Typ</InputLabel>
                                            <Select
                                                required={this.props.required}
                                                labelId="projectType-label"
                                                id="projectType-select"
                                                value={this.state.selectedProjectType}
                                                onChange={this.handleProjectTypeChange}
                                                label="Datum-Operator"
                                            >
                                                <MenuItem defaultChecked={true} value="all">Alle Projekte</MenuItem>
                                                <MenuItem value="INTERN">Interne Projekte</MenuItem>
                                                <MenuItem value="EXTERNAL">Externe Projekte</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel control={<Checkbox checked={this.state.showArchived} onChange={this.handleCheckArchived} />} label="Archivierte Projekte anzeigen" />
                                    </Grid>
                                    <Grid className="Dashboard__filter-actions" item xs={12}>
                                        <Button variant="outlined" type="submit" color="primary">
                                            Filtern
                                        </Button>
                                        <Button variant="outlined" onClick={this.handleDeleteFilter} color="primary">
                                            Alle Filter löschen
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                            :
                            null
                        }

                    </Paper>
                    <Grid container spacing={3}>
                        {
                            this.state.loading ?
                                <Grid item xs={12} className="Dashboard__grid-item">
                                    <CircularProgress/>
                                </Grid>
                                :
                                <>
                                    <Grid item sm={false} md={12} className="Dashboard__grid-item">
                                        <Hidden smDown={true}>
                                            <StatisticGraph statistic={this.state.statistic}/>
                                        </Hidden>
                                    </Grid>
                                    <Grid item xs={12} className="Dashboard__grid-item">
                                        <Paper elevation={3}>
                                            <h3>
                                                Insgesamt erfasste Zeit: <b>{ this.state.statistic.overallMinutes / 60 + " Stunden" }</b>
                                            </h3>
                                        </Paper>
                                    </Grid>
                                    <Grid item sm={12} sm={4} className="Dashboard__grid-item">
                                            <ProjectStatistic projects={this.state.statistic.projects} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}  className="Dashboard__grid-item">
                                        <TeamMemberStatistic teamMembers={this.state.statistic.members} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="Dashboard__grid-item">
                                        <MonthStatistic months={this.state.statistic.months} />
                                    </Grid>
                                </>
                        }    
                    </Grid>   
                </div>
            </Layout>
        );
    }
}

export default connect()(Dashboard);