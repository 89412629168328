import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import './custom.css'
import {Settings} from "./components/Settings";
import TimeTracking from "./components/TimeTracking";
import Analyzer from "./components/Analyzer";
import TimeTrackingMulti from "./components/TimeTrackingMulti";
import Dashboard from "./components/Dashboard";
import AppSnackbarContainer from "./components/AppSnackbar";
import SignInForm from "./components/SignIn";
import MagicLinkSignInForm from "./components/MagicLinkSignIn";
import MagicLinkValidationContainer from "./components/MagicLinkValidation";
import {PrivateRoute} from "./utils/privateRoute";

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <div>
          <PrivateRoute  exact path='/' component={Dashboard} />
          <PrivateRoute exact path='/settings' component={Settings} />
          <PrivateRoute exact path='/tracking' component={TimeTracking} />
          <PrivateRoute exact path='/tracking/multi' component={TimeTrackingMulti} />
          <PrivateRoute exact path='/filter' component={Analyzer} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <Route exact path='/signin' component={SignInForm} />
          <Route exact path='/magicLink' component={MagicLinkSignInForm} />
          <Route exact path='/magicLink/validation' component={MagicLinkValidationContainer} />
          <AppSnackbarContainer />
      </div>
    );
  }
}