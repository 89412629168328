import Http from "./http";

export default class Project {
    constructor(route = "/project") {
        this.route = route;
    }

    getById(id) {
        return Http.get(`${this.route}/${id}`);
    }

    getAll() {
        return Http.get(`${this.route}/all`);
    }

    getAllUnarchivedProjects() {
        return Http.get(`${this.route}/all/unarchived`);
    }


    create(project) {
        return Http.post(`${this.route}`, project);
    }

    delete(projectId) {
        return Http.delete(`${this.route}/${projectId}`);
    }
    
    update(project) {
        return Http.put(`${this.route}/${project.projectId}`, project);
    }
    
    archive(projectId) {
        return Http.put(`${this.route}/${projectId}/archive`);
    }
    
    unarchive(projectId) {
        return Http.put(`${this.route}/${projectId}/unarchive`);
    }

}