import Http from "./http";
import TokenStorage from "../utils/tokenStorage";
import store from "../store/index";
import AuthenticationActions, {CHANGE_USER} from "../actions/authenticationActions";

export default class Authentication {

    login(email, password) {
        return new Promise((resolve, reject) => {
            Http.postWithoutToken('/auth',
                {
                    email: email,
                    password: password
                })
                .then((response) => {
                    if (response) {
                        TokenStorage.storeToken(response.jwtToken);
                        TokenStorage.storeRefreshToken(response.refreshToken);
                        resolve();
                    }
                })
                .catch(() => {
                    TokenStorage.clear();
                    reject();
                });
        });
    }

    sendMagicLink(email) {
        return Http.postWithoutToken("/auth/magicLink/send", { email });
    }

    logout() {
        TokenStorage.clear();
        store.dispatch(AuthenticationActions.clearUser());
    }

    loginWithMagicLinkToken(token) {
        return new Promise((resolve, reject) => {
            Http.postWithoutToken("/auth/magicLink/", {token})
                .then((response) => {
                    if (response) {
                        TokenStorage.storeToken(response.jwtToken);
                        TokenStorage.storeRefreshToken(response.refreshToken);
                        resolve();
                    }
                })
                .catch(() => {
                    TokenStorage.clear();
                    reject();
                });
        });
    }
}
